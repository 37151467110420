import * as React from 'react'
import ReactModal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/buttons/Button'
import classNames from 'classnames'
import Link from '@/utils/Link'
import Text from '@/utils/Text'
import IconWrapper from '@/utils/IconWrapper'
import CloseIcon from '@/icons/icon-close-xs.svg'
import CookiesNoticeAccordion from './CookiesNoticeAccordion'
import styles from './CookiesNotice.module.css'
import * as m from '@/utils/messages'

function CookiesNotice({ pathname }){
    const [seeCookieSettings, setSeeCookieSettings] = React.useState(false)
    const [necessaryExpanded, setNecessaryExpanded] = React.useState(false)
    const [nonNecessaryExpanded, setNonNecessaryExpanded] = React.useState(false)

    const dispatch = useDispatch()
    const { masquerade } = useSelector(state => state.user)
    const { cookiesAccepted, nonNecessaryCookiesEnabled } = useSelector(state => state.cookie)

    const isOnCookiePolicy = pathname === '/cookie-policy/'

    return(
        <>
            {!seeCookieSettings && !cookiesAccepted && !masquerade?.userId && !isOnCookiePolicy && (
                <div className={classNames(styles.cookieBanner, 'z-40 rounded-10 bg-grey-20 p-md ml-sm mb-xl md:mb-md')}>
                    <div className={classNames(styles.cookieBannerInner)}>
                        <p>We use some essential cookies to make this site work. We may use services from Vimeo and Youtube that may also use cookies. 
                            <br />
                            Manage cookies will allow you to choose your non-essential cookie settings. More information can be found in our  
                            <Link to="/cookie-policy/" > Cookie policy.</Link>
                        </p>
                        <Button
                            className={classNames(styles.acceptCookiesButton, 'ml-xl')}
                            onClick={() => {
                                dispatch({
                                    type: 'cookie/ACCEPT_COOKIES'
                                })
                            }}
                        >
                            Accept cookies
                        </Button>
                        <Button
                            className={classNames(styles.acceptCookiesButton, 'ml-sm')}
                            onClick={() => setSeeCookieSettings(true)}
                        >
                            Manage cookies
                        </Button>
                    </div>
                </div>
            )}
            {seeCookieSettings && !cookiesAccepted && !masquerade?.userId && !isOnCookiePolicy && (
                <ReactModal
                    isOpen
                    closeTimeoutMS={300}
                    className="outline-none"
                    overlayClassName="perf-fixed z-100 inset-0 w-full flex items-center justify-center"
                >
                    <div className="relative md:w-600 mx-auto overflow-hidden bg-white shadow rounded-20 md:rounded-30 lg:round p-lg">
                        <div className="relative z-10">
                            <Text as='h2' className='mb-lg md:ml-sm'>Cookie Settings</Text>
                            <button
                                type='button'
                                onClick={() => setSeeCookieSettings(false)}
                                className='absolute flex items-center top-sm right-sm'
                            >
                                <IconWrapper
                                    wrapperClassName="w-md text-primary"
                                    className="fill-current"
                                    icon={CloseIcon}
                                />
                            </button>
                            <Text as='p' className='mb-lg md:ml-sm'>We use some essential cookies to make this site work. We may use services from Vimeo and Youtube that may also use cookies.</Text>
                            <CookiesNoticeAccordion
                                expanded={necessaryExpanded}
                                setExpanded={setNecessaryExpanded}
                                index={1}
                                text={'Essential cookies'}
                                necessary
                            >
                                <Text as='p' className='text-xs md:text-base mb-md'>
                                    { m.NECESSARY_COOKIES }
                                </Text>
                            </CookiesNoticeAccordion>
                            <CookiesNoticeAccordion
                                className='mt-3xl'
                                expanded={nonNecessaryExpanded}
                                setExpanded={setNonNecessaryExpanded}
                                index={2}
                                text={'Video player cookies'}
                                necessary={false}
                                nonNecessary={nonNecessaryCookiesEnabled}
                            >
                                <Text as='p' className='text-xs md:text-base mb-md'>
                                    { m.NON_NECESSARY_COOKIES }
                                </Text>
                            </CookiesNoticeAccordion>
                        </div>
                    </div>
                </ReactModal>
            )}
        </>
    )
}

export default CookiesNotice